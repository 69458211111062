import classNames from "classnames"
import React, { ReactNode } from "react"

import SocialProofingSlider from "./SocialProofingSlider"
import { Color } from "../../../../constants/V2/color"
import "./styles.css"
import PillButton from "../../../elements/V2/Buttons/PillButton"
import Typography from "../../../elements/V2/Typography"

import { useScrollAnimateSelf } from "@hooks/V2/useScrollAnimate"
import {
  getAccentColor,
  getBackgroundColorClass,
  getGradientFromColorClass,
  getGradientToColorClass,
} from "@utils/V2/color"

type Props = {
  titleText: string
  superscriptText: string
  primaryBackgroundColor: Color
  secondaryBackgroundColor?: Color
  buttonText: string
  buttonLink: string
  buttonColor: Color
  children: ReactNode
}

const SocialProofing = ({
  titleText,
  superscriptText,
  primaryBackgroundColor,
  secondaryBackgroundColor,
  buttonText,
  buttonLink,
  buttonColor,
  children,
  ...props
}: Props) => {
  const { animationRefCallback } = useScrollAnimateSelf(true)

  return (
    <div
      ref={animationRefCallback}
      className={classNames(
        "relative col-span-full inline-block h-full w-full max-w-full overflow-hidden rounded-32 py-40 text-left sm:text-center md:py-60 lg:py-80",
        getAccentColor(primaryBackgroundColor),
        !secondaryBackgroundColor
          ? getBackgroundColorClass(primaryBackgroundColor)
          : [
              "bg-gradient-to-br",
              getGradientFromColorClass(primaryBackgroundColor),
              getGradientToColorClass(secondaryBackgroundColor),
            ]
      )}
      data-testid="social-proofing-component"
      {...props}
    >
      <Typography
        text={superscriptText}
        size="subscript-md"
        font="grotesk"
        weight="book"
        color={getAccentColor(primaryBackgroundColor)}
        className="mb-8 px-24 uppercase"
      />
      <Typography
        text={titleText}
        size="lead-md"
        font="grotesk"
        weight="medium"
        color={getAccentColor(primaryBackgroundColor)}
        className="mb-40 px-24"
      />
      <SocialProofingSlider>{children}</SocialProofingSlider>
      <div className="mt-60 flex px-24 sm:justify-center">
        <PillButton
          style={secondaryBackgroundColor ? "bordered" : "solid"}
          size="large"
          color={buttonColor}
          text={buttonText}
          linkUrl={buttonLink}
          overrideTextColor={secondaryBackgroundColor ? Color.White : undefined}
        />
      </div>
    </div>
  )
}

export default SocialProofing
