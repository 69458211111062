import { StoryblokComponent, storyblokEditable } from "gatsby-source-storyblok"
import React from "react"

import SocialProofing from "../../../V2/Sections/SocialProofing"

import { getUrlFromStoryblokLink } from "@utils/storyblok"

const StoryblokSocialProofing = ({
  blok,
}: Storyblok.BlokProps<Storyblok.SocialProofing>) => {
  const swiperTestimonials = [
    ...blok.testimonials,
    ...blok.testimonials,
    ...blok.testimonials,
    ...blok.testimonials,
  ]
  /*
    Testimonials are duplicated for continuous scrolling without inconsistent spacing.
    Length of the array must always be an even number. Length can be shorter or longer depending on
    the width of a single testimonials container and the largest screen width we want to support.
  */

  return (
    <SocialProofing
      titleText={blok.titleText}
      superscriptText={blok.superscriptText}
      primaryBackgroundColor={blok.primaryBackgroundColor}
      secondaryBackgroundColor={blok.secondaryBackgroundColor}
      buttonText={blok.buttonText}
      buttonLink={getUrlFromStoryblokLink(blok.buttonLink)}
      buttonColor={blok.buttonColor}
      // @ts-ignore to have more strict typing compared to Storybloks generic object typing
      {...storyblokEditable(blok)}
    >
      {swiperTestimonials.map((testimonial, index) => (
        <StoryblokComponent
          key={`${testimonial._uid}-${index}`}
          blok={testimonial}
        />
      ))}
    </SocialProofing>
  )
}

export default StoryblokSocialProofing
