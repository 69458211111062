import React, { Children } from "react"
import SwiperCore, { Autoplay } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "./styles.css"

type Props = {
  children: React.ReactNode
}

SwiperCore.use([Autoplay])

const SocialProofingSlider = ({ children }: Props) => {
  return (
    <Swiper
      id="social-proofing"
      spaceBetween={20}
      slidesOffsetBefore={20}
      slidesOffsetAfter={20}
      slidesPerView="auto"
      loop={true}
      autoplay={{
        delay: 0,
        disableOnInteraction: false,
      }}
      freeMode
      grabCursor
      watchOverflow
      breakpoints={{
        900: {
          spaceBetween: 24,
        },
        1200: {
          spaceBetween: 32,
        },
      }}
      speed={25000}
      className="overflow-visible"
    >
      {Children.map(children, (child, index) => (
        <SwiperSlide
          key={`swiper-slide-${index}`}
          className="grid h-auto w-[375px] last:mr-[calc(100vw-5.33vw-375px)]"
        >
          {child}
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

export default SocialProofingSlider
